<template>
    <div>
        <el-button type="primary">
            Skicka
        </el-button>
        <el-button type="primary" @click="showPDF">
            Förhandsvisning
        </el-button>
        <el-button type="primary" @click="addComment">
            Lägg till kommentar
        </el-button>
        <el-button type="primary" @click="addPayment" v-if="invoiceType == 'Faktura'">
            Betala
        </el-button>
        <el-button type="primary" @click="makeRefund" v-if="invoiceType == 'Faktura' && balance < 0 && currency == 'SEK'">
            Återbetalning
        </el-button>
        <el-button type="primary" @click="goFactoring" v-if="isEligibleForFactoring">
            Köp fakturan
        </el-button>
    </div>
</template>
<script>
export default {
    props: {
        invoiceType: {
            type: String,
            default: "",
        },
        balance: {
            type: Number,
            default: 0,
        },
        currency: {
            type: String,
            default: "SEK",
        },
        isEligibleForFactoring: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        showPDF() {
            var token = this.$auth.getToken();
            var headers = "?token=" + token;
            var base = this.axios.defaults.baseURL;
            var url = `/invoices/${this.$route.params.invoiceId}/pdf`;
            window.open(base + url + headers, `_blank`, `width=800, height=800`);
        },

        addComment() {
            this.$emit("addComment");
        },

        addPayment() {
            this.$emit("addPayment");
        },

        makeRefund() {
            this.$emit("makeRefund");
        },

        goFactoring() {
            this.$dialog.html().alert("Denna funktion är inte implementerad än!");
            this.$emit("goFactoring");
        },
    },
};
</script>
